<template>
    <base-modal
        close
        :show="show"
        size="md"
        :loading="loading"
        @modal-close="closeModal"
    >
        <template #modal-header>
            <h5 class="text-lg font-medium text-gray-900">
                {{ $t('documents.list.uploadModal.title') }}
            </h5>
        </template>
        <div class="flex flex-col">
            <form-file-upload
                v-model="files"
                :description="$t('documents.list.uploadFile.description')"
                :accepted-files="[
                    '.jpg',
                    '.jpeg',
                    '.png',
                    '.pdf',
                    '.gif',
                    '.doc',
                    '.docx',
                    '.xls',
                    '.xlsx',
                ]"
            />
            <div class="">
                <h5 class="mb-2 text-sm font-medium text-gray-700">
                    {{ $t('documents.list.uploadModal.fileRestriction') }}
                </h5>
                <ul class="flex flex-col gap-2.5 py-2.5">
                    <li
                        class="flex items-center gap-2.5 text-xs font-normal text-gray-600"
                    >
                        <div
                            class="flex h-5 w-5 items-center justify-center rounded-full bg-primary-100"
                        >
                            <base-icon
                                name="line-icons:general:check"
                                size="sm"
                            />
                        </div>
                        <span>
                            {{
                                $t('documents.list.uploadModal.maxFileSize', {
                                    size: 5,
                                })
                            }}
                        </span>
                    </li>
                    <li
                        class="flex items-center gap-2.5 text-xs font-normal text-gray-600"
                    >
                        <div
                            class="flex h-5 w-5 items-center justify-center rounded-full bg-primary-100"
                        >
                            <base-icon
                                name="line-icons:general:check"
                                size="sm"
                            />
                        </div>
                        <span>
                            {{
                                $t('documents.list.uploadModal.allowFile', {
                                    files: allowFiles.join(', '),
                                })
                            }}
                        </span>
                    </li>
                    <li
                        class="flex items-center gap-2.5 text-xs font-normal text-gray-600"
                    >
                        <div
                            class="flex h-5 w-5 items-center justify-center rounded-full bg-primary-100"
                        >
                            <base-icon
                                name="line-icons:general:check"
                                size="sm"
                            />
                        </div>
                        <span>
                            {{
                                $t('documents.list.uploadModal.fileNameContain')
                            }}
                        </span>
                    </li>
                    <li
                        class="flex items-center gap-2.5 text-xs font-normal text-gray-600"
                    >
                        <div
                            class="flex h-5 w-5 items-center justify-center rounded-full bg-primary-100"
                        >
                            <base-icon
                                name="line-icons:general:check"
                                size="sm"
                            />
                        </div>
                        <span>
                            {{
                                $t(
                                    'documents.list.uploadModal.fileNameMustContain'
                                )
                            }}
                        </span>
                    </li>
                </ul>
            </div>
        </div>
        <template #modal-footer>
            <div class="flex justify-between gap-3">
                <base-button
                    outline
                    full-width
                    variant="default"
                    @click="closeModal"
                >
                    {{ $t('general.cancel') }}
                </base-button>
                <base-button full-width :disabled="!hasFiles" @click="onUpload">
                    {{ $t('general.upload') }}
                </base-button>
            </div>
        </template>
    </base-modal>
</template>
<script setup>
import { isEmpty } from 'lodash-es'

const emit = defineEmits(['on-finished-upload', 'modal-close'])

defineProps({
    show: {
        type: Boolean,
        default: false,
    },
})

const { t } = useI18n()
const { errorNotify } = useNotification()
const allowFiles = ['JPG', 'PNG', 'PDF', 'GIF', 'DOC', 'DOCX', 'XLS', 'XLSX']

const files = ref([])

const route = useRoute()

const hasFiles = computed(() => !isEmpty(unref(files)))

const { execute, loading } = useApi('/api/documents/files', 'POST')

const closeModal = () => {
    emit('modal-close')
}

const onUpload = () => {
    const uploadFiles = unref(files)
    const { id } = route.params

    const data = new FormData()
    for (const file of uploadFiles) {
        data.append('files[]', file)
    }

    if (id) {
        data.append('id', id)
    }

    execute({
        data,
        headers: { 'Content-Type': 'multipart/form-data' },
    })
        .then(() => {
            emit('on-finished-upload')
        })
        .catch(({ errorMessage }) => {
            errorNotify({
                title: t('documents.list.uploadModal.uploadError'),
                text: errorMessage,
            })
        })
}
</script>
