import { isActive } from '@tenant/core/feature'

const navigation = [
    {
        id: 'sas-documents',
        path: 'documents',
        label: 'documents.sidebar.label',
        icon: 'duocolor-icons:files:file-04',
        position: 70,
        permission: 'view_documents',
    },
    {
        id: 'sas-documents-overview',
        path: 'documents.overview',
        label: 'documents.sidebar.overviewLabel',
        parent: 'sas-documents',
        position: 1,
    },
    ...(isActive('FEATURE_FLAG_1080')
        ? [
              {
                  id: 'sas-documents-chat',
                  path: 'documents.chat',
                  label: 'documents.sidebar.chatLabel',
                  parent: 'sas-documents',
                  position: 2,
              },
          ]
        : []),
]

export default navigation
