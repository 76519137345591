<template>
    <div class="flex items-center gap-3 text-gray-600">
        <template v-if="folder">
            <base-icon
                variant="inherit"
                size="md"
                name="line-icons:arrows:flip-backward"
                class="cursor-pointer"
                @click="goToFolder(parentFolder?.uuid || null)"
            />
            <div
                class="flex items-center gap-1 text-sm font-medium text-gray-900"
            >
                <div class="cursor-pointer" @click="goToFolder(null)">
                    {{ $t('documents.list.title') }}
                </div>
                <template v-if="folderAncestors.length < 4">
                    <template
                        v-for="folder in folderAncestors"
                        :key="folder.uuid"
                    >
                        <span>/</span>
                        <div
                            class="cursor-pointer"
                            @click="goToFolder(folder.uuid)"
                        >
                            {{ getFolderName(folder) }}
                        </div>
                    </template>
                </template>

                <template v-else>
                    <template v-if="firstParentFolder">
                        <span>/</span>
                        <div
                            class="cursor-pointer"
                            @click="goToFolder(firstParentFolder.uuid)"
                        >
                            {{ getFolderName(firstParentFolder) }}
                        </div>
                    </template>
                    <template v-if="parentFolder">
                        <span v-if="firstParentFolder">/.../</span>
                        <span v-else>/</span>
                        <div
                            class="cursor-pointer"
                            @click="goToFolder(parentFolder.uuid)"
                        >
                            {{ getFolderName(parentFolder) }}
                        </div>
                    </template>
                </template>

                <template v-if="folder">
                    <span>/</span>
                    <div
                        class="cursor-pointer"
                        @click="goToFolder(folder.uuid)"
                    >
                        {{ getFolderName(folder) }}
                    </div>
                </template>
            </div>
        </template>
        <template v-else>
            <base-icon
                name="line-icons:general:home-04"
                variant="inherit"
                size="md"
            />
            <div class="text-sm font-medium text-gray-900">
                {{ $t('documents.list.title') }}
            </div>
        </template>
    </div>
</template>

<script setup>
import { getFolderName } from '../utils/helper.utils'

const emit = defineEmits(['go-to-folder'])
const props = defineProps({
    folder: {
        type: Object,
        required: false,
    },
})

const folderAncestors = computed(() => {
    return props.folder?.ancestors || []
})

const firstParentFolder = computed(() => {
    const ancestors = folderAncestors.value || []
    if (ancestors.length < 2) {
        return null
    }

    return ancestors[0] || null
})

const parentFolder = computed(() => {
    const ancestors = folderAncestors.value || []
    if (ancestors.length === 0) {
        return null
    }

    return ancestors[ancestors.length - 1] || null
})

const goToFolder = (folderId) => {
    emit('go-to-folder', folderId)
}
</script>
