<template>
    <div class="flex h-full flex-col">
        <base-heading
            class="px-6 py-3.5"
            :title="$t('documents.chat.title')"
            :description="$t('documents.chat.description')"
        />

        <div v-if="!document">
            <form-file-upload
                :description="$t('documents.chat.uploadFile.description')"
                :accepted-files="['.pdf']"
                :maxFiles="1"
                @on-upload-files="onUploadFile"
            />
        </div>
        <div
            v-else
            class="relative flex max-h-[calc(100%-97px)] grow flex-col pb-20"
        >
            <div class="space-y-2.5 overflow-auto">
                <div v-for="(item, index) in items" :key="index">
                    <documents-chat-answer
                        v-if="item.type == 'answer'"
                        :content="item.value"
                    />
                    <documents-chat-question
                        v-else-if="item.type == 'question'"
                        :content="item.value"
                    />
                </div>
            </div>

            <div class="absolute bottom-0 left-0 right-0">
                <documents-chat-form @submit="onSubmitQuestion" />
            </div>
        </div>
    </div>
</template>

<script setup>
const { t } = useI18n()
const { successNotify, errorNotify } = useNotification()

const document = ref(null)
const items = ref([
    {
        type: 'answer',
        value: "<p>The document you have uploaded is an invoice for a Creative Cloud All Apps 100GB subscription.</p> <p>The subscription is valid from 26-MAY-2023 to 25-JUN-2023.</p> <p>The invoice total is EUR 52.06, and no taxes were charged.</p> <p>The customer's VAT number is CY10406574Y, and they are responsible for self-assessing VAT.</p> <p>The seller is Adobe Systems Software Ireland LtdHere are three possible questions you may have.</p>",
    },
])

const onUploadFile = async (uploadFile) => {
    try {
        if (!uploadFile) {
            return
        }

        document.value = uploadFile

        successNotify({
            title: t('documents.chat.uploadFile.uploadSuccessful'),
        })
    } catch (e) {
        errorNotify({
            title: t('documents.chat.uploadFile.uploadError'),
        })
    }
}

const onSubmitQuestion = (value) => {
    items.value.push({
        type: 'question',
        value: value,
    })
}
</script>
