<template>
    <data-grid-row class="cursor-pointer">
        <data-grid-cell>
            <div class="flex flex-1 items-center gap-x-3">
                <div class="rounded-full bg-primary-100 p-2.5">
                    <base-icon
                        name="line-icons:files:folder"
                        variant="primary"
                    />
                </div>
                <documents-form-name
                    v-model="state.name"
                    :loading="loading"
                    :error-message="errorMessage"
                    @on-submit="onSaveName"
                />
            </div>
        </data-grid-cell>
    </data-grid-row>
</template>

<script setup>
const emit = defineEmits(['created-directory'])
const state = reactive({
    name: null,
})
const route = useRoute()

const { execute, loading, errorMessage } = useApi(
    '/api/documents/directories',
    'POST'
)

const onSaveName = (name) => {
    execute({
        data: {
            name,
            id: route.params.id,
        },
    }).then(() => {
        emit('created-directory')
    })
}
</script>
