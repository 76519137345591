<template>
    <div class="flex gap-5 bg-white py-4">
        <form-text-input
            v-model="chatQuestion"
            class="grow"
            :placeholder="$t('documents.chat.form.input.placeholder')"
        />

        <base-button size="lg" class="shrink-0" @click="onSubmitQuestion">
            {{ $t('documents.chat.form.submit') }}
        </base-button>
    </div>
</template>

<script setup>
const { value: chatQuestion } = useField('chatQuestion')

const emit = defineEmits(['submit'])

const onSubmitQuestion = () => {
    emit('submit', chatQuestion.value)
    chatQuestion.value = ''
}
</script>
