import { GUARD } from '@tenant/utils/constants'
import { isActive } from '@tenant/core/feature'

const routes = [
    {
        name: 'documents',
        path: '/documents',
        redirect: {
            name: 'documents.overview',
        },
        children: [
            {
                name: 'documents.overview',
                path: 'overview/:id?',
                component: () => import('./pages/documents-list.vue'),
                meta: {
                    noPadding: true,
                },
            },
            ...(isActive('FEATURE_FLAG_1080')
                ? [
                      {
                          name: 'documents.chat',
                          path: 'chat',
                          component: () => import('./pages/documents-chat.vue'),
                      },
                  ]
                : []),
        ],
    },
]

export default {
    routes,
    meta: {
        layout: 'dashboard-layout',
        guard: GUARD.TENANT,
        permission: 'view_documents',
    },
}
