import { getContactName, getEmployeeName } from '@tenant/utils/helper'

export const getFolderName = (item) => {
    const { contact, employee } = item
    if (contact) {
        return getContactName(contact)
    }

    if (employee) {
        return getEmployeeName(employee)
    }

    return item.full_name
}
