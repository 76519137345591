<template>
    <base-sticky-heading
        class="min-h-[82px] items-center"
        :title="$t('documents.list.title')"
        :description="$t('documents.list.description')"
    >
        <template #right>
            <div class="flex gap-3">
                <base-button
                    v-if="$acl.can('create_documents')"
                    variant="primary"
                    type="submit"
                    icon="line-icons:general:upload-cloud-01"
                    :label="$t('documents.list.button.uploadFile')"
                    @click="showUploadFileModal"
                >
                </base-button>
                <base-button
                    v-if="$acl.can('create_documents')"
                    variant="primary"
                    type="submit"
                    icon="duocolor-icons:files:folder"
                    :label="$t('documents.list.button.addNewFolder')"
                    :disabled="!folderEditable"
                    @click="toggleAddNewFolder"
                />
            </div>
        </template>

        <data-grid
            :data-source="entities.data"
            :columns="columns"
            :loading="loadingSearch"
            :empty-text="$t('documents.list.emptyText')"
            :empty-sub="$t('documents.list.emptySubText')"
            @row-click="openFolder"
            table-borderless
        >
            <template #header>
                <documents-grid-header
                    :folder="entities.folder"
                    @go-to-folder="goToFolder"
                />
            </template>

            <template v-if="state.addingNewFolder" #additional-top-row>
                <documents-add-folder @created-directory="createdDirectory" />
            </template>

            <template #column-name="{ item }">
                <div class="flex flex-1 items-center gap-x-3">
                    <template v-if="isFolder(item)">
                        <div class="rounded-full bg-primary-100 p-2.5">
                            <base-icon
                                name="line-icons:files:folder"
                                variant="primary"
                            />
                        </div>
                        <template v-if="state.renameItem?.uuid === item.uuid">
                            <documents-form-name
                                v-model="state.renameItem.name"
                                :disabled="loadingRename"
                                :error-message="errorMessageRename"
                                @click.stop
                                @on-submit="onRename"
                            />
                        </template>
                        <div v-else class="flex flex-col">
                            <span class="text-sm font-medium text-gray-900">
                                {{ getFolderName(item) }}
                            </span>
                            <span
                                v-if="item.contact"
                                class="text-sm font-normal text-gray-500"
                            >
                                <base-tooltip
                                    dark
                                    hover
                                    :content="
                                        $t('documents.list.customerNumber')
                                    "
                                >
                                    {{ item.contact.customer_number }}
                                </base-tooltip>
                            </span>
                            <span
                                v-else-if="
                                    item.employee?.private_information
                                        ?.date_of_birth
                                "
                                class="text-sm font-normal text-gray-500"
                            >
                                <base-tooltip
                                    dark
                                    hover
                                    :content="
                                        $t('documents.list.customerNumber')
                                    "
                                >
                                    {{
                                        $filters.dateShortFormat(
                                            item.employee.private_information
                                                .date_of_birth
                                        )
                                    }}
                                </base-tooltip>
                            </span>
                        </div>
                    </template>
                    <template v-else>
                        <div
                            class="rounded-full bg-gray-100 p-2.5 text-gray-600"
                        >
                            <base-icon
                                :name="iconFileName(item)"
                                variant="inherit"
                            />
                        </div>
                        <template v-if="state.renameItem?.uuid === item.uuid">
                            <documents-form-name
                                v-model="state.renameItem.name"
                                :disabled="loadingRename"
                                :error-message="errorMessageRename"
                                @click.stop
                                @on-submit="onRename"
                            />
                        </template>
                        <div v-else class="flex flex-col">
                            <div class="text-sm font-medium text-gray-900">
                                {{ item.full_name }}
                            </div>
                            <span class="text-sm font-normal text-gray-500">
                                {{ $filters.humanFileSize(item.size) }}
                            </span>
                        </div>
                    </template>
                </div>
            </template>

            <template #column-size="{ item }">
                <div v-if="item.size" class="text-sm font-normal text-gray-500">
                    {{ $filters.humanFileSize(item.size) }}
                </div>
            </template>

            <template #column-created_at="{ item }">
                <div
                    v-if="item.created_at"
                    class="text-sm font-normal text-gray-500"
                >
                    <template v-if="!isFolder(item)">
                        {{ $filters.dateTimeHumanFormat(item.created_at) }}
                    </template>
                </div>
            </template>

            <template #column-updated_at="{ item }">
                <div
                    v-if="item.updated_at"
                    class="text-sm font-normal text-gray-500"
                >
                    <template v-if="!isFolder(item)">
                        {{ $filters.dateTimeHumanFormat(item.updated_at) }}
                    </template>
                </div>
            </template>

            <template #column-author="{ item }">
                <div
                    v-if="item.author"
                    class="text-sm font-normal text-gray-500"
                >
                    {{ item.author.full_name }}
                </div>
            </template>

            <template #column-action="{ item }">
                <base-context-menu>
                    <base-context-menu-item
                        v-if="isFolder(item)"
                        icon="line-icons:general:eye"
                        @click="openFolder(null, item)"
                    >
                        {{ $t('documents.list.contextMenu.open') }}
                    </base-context-menu-item>

                    <base-context-menu-item
                        icon="line-icons:general:download-01"
                        v-else
                        @click="onDownload(item)"
                    >
                        {{ $t('documents.list.contextMenu.download') }}
                    </base-context-menu-item>

                    <base-context-menu-item
                        v-if="$acl.can('update_documents')"
                        icon="line-icons:editor:move"
                    >
                        {{ $t('documents.list.contextMenu.move') }}
                    </base-context-menu-item>

                    <base-context-menu-item
                        v-if="item.editable && $acl.can('update_documents')"
                        icon="line-icons:editor:text-input"
                        @click="setRename(item)"
                    >
                        {{ $t('documents.list.contextMenu.rename') }}
                    </base-context-menu-item>

                    <base-context-menu-item
                        v-if="item.editable && $acl.can('delete_documents')"
                        icon="line-icons:general:trash-01"
                        class="text-danger-600"
                        @click="setEntity(item)"
                    >
                        {{ $t('general.remove') }}
                    </base-context-menu-item>
                </base-context-menu>
            </template>

            <template #footer>
                <grid-pagination
                    :total="entities.last_page"
                    :page="entities.current_page"
                />
            </template>
        </data-grid>
    </base-sticky-heading>

    <base-delete-modal
        :loading="loadingDelete"
        :show="itemDelete?.uuid"
        :name="itemDelete?.name"
        @modal-close="setEntity(null)"
        @confirm-delete="confirmDelete"
    />
    <documents-upload-files
        :show="state.uploadFileModal"
        @modal-close="hideUploadFileModal"
        @on-finished-upload="onFinishUpload"
    />
</template>

<script setup>
import DocumentsGridHeader from '@tenant/modules/tenant/documents/components/documents-grid-header.vue'
import { getFolderName } from '@tenant/modules/tenant/documents/utils/helper.utils'
import { iconFileName, isFolder } from '@tenant/utils/helper'

const router = useRouter()
const route = useRoute()
const { t } = useI18n()
const emitter = useEmitter()

const {
    entities,
    entity: itemDelete,
    setEntity,
    useApiSearch,
    useApiDelete,
} = useApiFactory('documents')
const {
    loading: loadingSearch,
    execute: executeSearch,
    executeRefreshSearch,
} = useApiSearch({}, false)
const { loading: loadingDelete, execute: executeDelete } = useApiDelete()

const {
    execute: executeRename,
    loading: loadingRename,
    errorMessage: errorMessageRename,
} = useApi('/api/documents/rename/{id}', 'PATCH')

const { downloadFile } = useFileStorage()

const state = reactive({
    addingNewFolder: false,
    uploadFileModal: false,
    renameItem: null,
})

const folderEditable = computed(() => {
    if (!entities.value.folder) {
        return true
    }

    return entities.value.folder.editable
})

useHead({
    title: t('documents.list.title'),
})

watch(
    () => [route.params, route.query],
    () => {
        state.addingNewFolder = false

        executeSearch({
            ...route.params,
            ...route.query,
        })
    },
    { immediate: true, flush: 'post' }
)

const columns = [
    {
        property: 'name',
        label: t('documents.list.columns.fileName'),
    },
    {
        property: 'size',
        label: t('documents.list.columns.fileSize'),
    },
    {
        property: 'created_at',
        label: t('documents.list.columns.dateUploaded'),
    },
    {
        property: 'updated_at',
        label: t('documents.list.columns.lastUpdated'),
    },
    {
        property: 'author',
        label: t('documents.list.columns.uploadedBy'),
    },
]

const toggleAddNewFolder = () => {
    state.addingNewFolder = !state.addingNewFolder
}

const createdDirectory = () => {
    state.addingNewFolder = false
    executeRefreshSearch()
}

const showUploadFileModal = () => {
    state.uploadFileModal = true
}

const hideUploadFileModal = () => {
    state.uploadFileModal = false
}

const onFinishUpload = () => {
    hideUploadFileModal()
    executeRefreshSearch()
}

const openFolder = (_, item) => {
    if (!isFolder(item)) {
        return
    }

    goToFolder(item.uuid)
}

const onDownload = async (item) => {
    if (!item.preview) {
        return
    }

    try {
        emitter.emit('set-loading', true)
        await downloadFile(item.preview.path, item.full_name)
    } finally {
        emitter.emit('set-loading', false)
    }
}

const goToFolder = (id) => {
    router.push({
        name: 'documents.overview',
        params: {
            id,
        },
    })
}

const confirmDelete = () => {
    executeDelete(unref(itemDelete).uuid).then(() => {
        setEntity(null)
        executeRefreshSearch()
    })
}

const setRename = (item) => {
    state.renameItem = item
}

const onRename = () => {
    const { renameItem } = state

    executeRename({
        params: {
            id: renameItem.uuid,
        },
        data: {
            name: renameItem.name,
        },
    }).then(() => {
        state.renameItem = null
        executeRefreshSearch()
    })
}
</script>
